import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import ProjectHero from "../components/ProjectHero";
import "./Article.css"
import ProjectCard from "../components/ProjectCard";


export default props => {
  return (
    <Layout>
        <ProjectHero
          title="Gaido Health"
          intro="Gaido is an AI-powered remote patient monitoring system. During my internship with Boston Consulting Group, I designed the Gaido mobile app in the product definition phase."
          client="A leading pharmaceutical company"
          team="Product team of ~20 (PM + design + DS + SME), Design team of 3, 4 weeks in 2018"
          myrole="Wireframing, Interaction design, UI design"
          />

      <Img fluid={props.data.hero.childImageSharp.fluid} />


    {/** :::: INTRO :::: **/}
    <div className="ArticleSection">
      <h1 style={{color:"#477FFB"}}>Intro —―</h1>
      <div className="ArticleBody">
        <h2>How might we make chemotherapy less risky for cancer patients?</h2>
        <p>
        Chemotherapy is a risky treatment. In an ideal world, patients should stay in the hospital for 7-10 days after their therapy, just so they can get timely treatment in case of an emergency. However, today in the United States, most patients are discharged from hospital shortly after their treatment. For some patients, this period could be as short as 3 days. <em>How can we make post-treatment time safer for cancer patients?</em>
        </p>
      </div> 
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Gaido, a remote patient monitoring system</h2>
        <p>
        To solve that problem, we built <em>Gaido</em> — a remote monitoring system that tracks patients’ vital signs when they return home. There are three components:
        </p>
        <ul>
          <li>Patient end: a mobile app + wearable device that tracks the patient’s five vital signs</li>
          <li>Nurse end: a dashboard that lets nurses track their patient's vitals and receive alerts</li>
          <li>A Machine Learning (ML) algorithm that detects potential risks and looks for optimal intervention points</li>
        </ul>
        <Img fluid={props.data.overview.childImageSharp.fluid} /><br/>
        <p>During my time at BCG, I designed the <em>patient-end mobile app</em>. My responsibilities spanned across scenario construction, wireframing, prototyping, and UI design. </p>
        <p>
        Let me first walk you through my final design :)
        </p>
      </div> 
    </div>



    {/** :::: FINAL DESIGN :::: **/}
    <div className="ArticleSection">
      <h1 style={{color:"#477FFB"}}>Final design &mdash;&mdash;</h1>
      <div className="ArticleBody">
        <h2>User scenario</h2>
        <p>
        Derek, 58, just finished his second chemotherapy. Derek's health condition stabilized two days after the treatment, and his doctor decided to discharge him in 48 hours. Since Derek has signed up for Gaido prior to the threpy, Gaido's support staff comes over to help him onboard the system. 
        </p>
        <p>⚠️ 
        <i> Disclaimer: I created all the screens below based on my work at BCG. Other designers continued to work on the project after I left the team, so the actual UIs may look different.</i></p>
      </div> 
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Onboard the system</h2>
        <p>
        With the help from the support person, Derek downloads the Gaido app from his phone, creates a profile, and puts on the wearable devices that track his vitals.
        </p>
      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.flow1.childImageSharp.fluid} /><br/>
      </div>
    </div>


    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Track the vital signs</h2>
        <p>
        Two days later, Derek returns home. He can easily check his vitals at any time through the Gaido app.
        </p>
      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.flow2.childImageSharp.fluid} /><br/>
      </div>
    </div>


    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Take a Patient-Reported Outcome (PRO) survey</h2>
        <p>
        One day, Derek’s core temperature rises to 102.5 F. He receives a prompt from Gaido to fill out a wellness update survey. He answers a few questions about his symptoms, recent activities, and moods, and learns that the record will be shared with his doctors. Gaido also encourages Derek to call his hospital if the symptoms don’t go away.
        </p>

      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.flow3.childImageSharp.fluid} /><br/>
      </div>
    </div>


    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Manage data and consent</h2>
        <p>
        Because Gaido collects health data (which is sensitive personal information), we need to follow the highest privacy standards and give Derek the options to download/delete data, revoke consent, as well as control how his data can be used at a granular level.
        </p>
      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.flow4.childImageSharp.fluid} /><br/>
      </div>
    </div>


    {/** :::: PROCESS :::: **/}
    <div className="ArticleSection">
      <h1 style={{color:"#477FFB"}}>Process —―</h1>
      <div className="ArticleBody">
        <h2>Game plan</h2>
      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.process.childImageSharp.fluid} /><br/>
      </div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Vital signs are useless?</h2>
        <p>
        After the initial concept testing, we ran into an unexpected issue: testing participants (doctors and triage nurses) told us that vital signs are actually much less useful than what we had assumed:</p>

        <ol>
          <li><em>False alerts are inevitable and hard to detect.</em> For cancer patients, the boundary between normal and abnormal vital signs can be extremly fuzzy. Patients are likely to have pre-existing conditions that would make their vitals look abnormal, even when they're doing well.</li>
          <li><em>Quantitive data alone doesn't tell much about what has happened.</em> Although doctors and nurses found it valueable to see trend over time, they were not sure about how to interpret the data and how to give medical advices. Further clinical contexts such as patient-report symptoms are crutial for people to make sense of the vital signs. </li>
        </ol>

        <p>
        Based on the feedback, we realized two missing pieces in the initial proposal: <br/>
        <em>1. Expectation calibration.</em><br/>
        We need to manage user’s expectations for the accuracy of the system. To address this, we decided to send alerts only when Gaido detects an absolute emergency (eg. when the patient’s blood oxygen level drops dramatically). 
        </p>
        <p>
        <em>2. Context information that augments the quantitive data. </em> <br/>
        Based on suggestions from SMEs, we decided to send Patient-Reported Outcome (PRO) surveys to users through the mobile app. PRO is a widely used tool for nurses to understand a patient's condition, such as symptoms, recent activities, and moods. 
        </p>
      </div> 
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Setting design goals: Balance user needs with data needs</h2>
        <p>As we shifted our focus from alert to collection of PRO collection, the tension between patient needs and data needs became much more prominent. <em>How might we avoid treating early adopters as mere "data providers"?</em> How might we provide more immediate value for them? To address that,  I added user values as a top design goal, and applied several strategies.</p>
      </div> 
    </div>

    <div className="BackgroundWrap">
      <div className="FullImage">
        <Img fluid={props.data.goals.childImageSharp.fluid} /><br/>
      </div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Strategy 1: Focus on high-value data points to avoid nonpurposive data collection</h2>
        <p>As our ML engineers became interested in including Patient Reported Outcome(PRO) in their model, they wanted us to “collect as much PRO as possible”. The original plan was to prompt users every two hours and make them fill out 10 surveys everyday via the mobile app. The problem is, that plan not only will affect the user experience, but also won’t get us high-quality data--if users aren't really engaged, there will always be workarounds to fool the system, which will result in low-quality data points. </p>
        <p>
        The only way to get good data is to engage our users -- to help them understand why the data is needed and how data recording would benefit them. To do that, we had to ask ourselves first: <em>What type of PRO data do we need?</em></p>

        <Img fluid={props.data.dataTypes.childImageSharp.fluid} /><br/>

        <p>I was able to identify high-value data points with that info, and map out the alert user flow to help the team to build a data collection plan that's more targeted and less annoying for patients:</p>

        <Img fluid={props.data.decisionTree.childImageSharp.fluid} /><br/>

      </div> 
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Strategy 2: Make raw data more useful when the ML model isn't mature enough to provide insights</h2>
        <p>To balance user needs and data needs, another opportunity I looked into was to make the display of vital signs more accessible to patients. We did five user tests to learn how patients perceive their vital signs, and improved the design accordingly.</p>
      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.dashboard.childImageSharp.fluid} /><br/>
      </div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Strategy 3: Help users calibrate their trust level in the system via visual design</h2>
        <p>The tension between user needs and data needs continued in the visual design stage. To begin with, we as a team picked three promising directions from a set of classic brand personas. Then I was then asked to explore the visual concepts for the three selected directions: ”companion”, “translator”, and “caregiver”.</p> <br/>

        <Img fluid={props.data.visualConcepts.childImageSharp.fluid} /><br/>

        <p>
        After looking at the three mock-ups, I realized that while “companion” and “caregiver” feel more warm and human, they can also make the system look more trustworthy than it really is, and thus introduce blind trust. Therefore, I decided to go with the "translator" style (which is more neutral and straightforward).
        </p>

      </div> 
    </div>

    <div className="BackgroundWrap" color="white">
      <div className="FullImage">
        <Img fluid={props.data.mockup.childImageSharp.fluid} /><br/>
      </div>
    </div>

    {/** :::: OUTCOME :::: **/}
    <div className="ArticleSection">
      <h1 style={{color:"#477FFB"}}>Outcome —―</h1>
      <div className="ArticleBody">
        <h2>Product results: Challenges turning into selling points</h2>
        <p>
        Gaido (alpha version) was successfully released in the iOS app store shortly after I left the team. The product has been through iterations since then and was acquired by a health tech company early this year. 
        </p>
        
        <p>
        What I found most interesting is that the inclusion of patient-reported outcome has been a major selling point for Gaido. That wouldn’t come into play without the human-centered design approach and close collaboration between design and ML engineering.
        </p>
      </div> 
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Designing early stage ML-powered systems</h2>
        <p>
        The tension between user needs and data needs is not unique to Gaido. If you’re also designing early stage ML-powered systems and have similar challenges, here's what you might want to consider:
        </p>
        <ul>
          <li>Be mindful of the type and amount of work your system may introduce to users</li>
          <li>Optimize your data collection strategy to minimize user tasks</li>
          <li>Help users to understand how much they should trust the system. Aovid language or design patterns that might introduce blind trust.</li>
          <li>If your ML model is not matured enough to produce reliable outcomes, look for alternative ways to bring value to early adopters.</li>
        </ul>
        <p>
        If you’re interested in UX of AI and want to chat with me, don’t hesitate to shoot an email :)
        </p>
      </div> 
    </div>

    <div className="ArticleSection">
          <div className="Next">
            <h6 style={{color:"#8E8E8E"}}>Next up</h6>
            <ProjectCard
            link="/gsa-online-portal/" 
            title="GSA Online Portal"
            tagline="Empower Amazon's Loss Prevention people better leverage their tools and resources"
            tag="Web App • Capstone Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/gsa-online-portal.png')}
            />
          </div>
        </div>


    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "gaido/hero.png" }){
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    overview: file(relativePath: { eq: "gaido/overview.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    flow1: file(relativePath: { eq: "gaido/flow-1.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    flow2: file(relativePath: { eq: "gaido/flow-2.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    flow3: file(relativePath: { eq: "gaido/flow-3.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    flow4: file(relativePath: { eq: "gaido/flow-4.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    process: file(relativePath: { eq: "gaido/process.jpg" }){
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    goals: file(relativePath: { eq: "gaido/goals.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }    

    dataTypes: file(relativePath: { eq: "gaido/data-types.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    decisionTree: file(relativePath: { eq: "gaido/decision-tree.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dashboard: file(relativePath: { eq: "gaido/dashboard.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    visualConcepts: file(relativePath: { eq: "gaido/visual-concepts.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }


    mockup: file(relativePath: { eq: "gaido/mockup.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nurse2: file(relativePath: { eq: "gaido/nurse-2.jpeg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    nurse3: file(relativePath: { eq: "gaido/nurse-3.jpeg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nurse4: file(relativePath: { eq: "gaido/nurse-4.jpeg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
  }
`
